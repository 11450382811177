<template>
  <ProductCellFieldProduct
    v-bind="$attrs"
    v-on="$listeners">
    <template
      #previewDialog="{
        dialog,
        manageDialog,
        selectRefFields,
        item,
        columnTitle,
      }">
      <AddingEqual
        :dialog="dialog"
        :item="item"
        :column-title="columnTitle"
        @manageDialog="manageDialog"
        @selectFields="selectRefFields" />
    </template>
  </ProductCellFieldProduct>
</template>
<script>
import ProductCellFieldProduct from '../ProductCellFieldProductBasic';
export default {
  name: 'ProductCellFieldProductEqual',
  components: {
    ProductCellFieldProduct,
    AddingEqual: () => import('./ProductCellFieldProductEqualAdding'),
  },
};
</script>